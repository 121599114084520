import eethoek1 from './eethoek1.jpg'
import eethoek2 from './eethoek2.jpg'
import werkkamer1 from './werkkamer1.jpg'
import werkkamer2 from './werkkamer2.jpg'
import woonkamer1 from './woonkamer1.jpg'
import woonkamer2 from './woonkamer2.jpg'
import woonkamer3 from './woonkamer3.jpg'
import woonkamer4 from './woonkamer4.jpg'
import badkamer1 from './badkamer1.jpg'
import badkamer2 from './badkamer2.jpg'
import keuken1 from './keuken1.jpg'
import keuken2 from './keuken2.jpg'
import keuken3 from './keuken3.jpg'
import keuken4 from './keuken4.jpg'


const beforeAndAfterPictures = [
  {before: eethoek1, after: eethoek2},
  {before: werkkamer1, after: werkkamer2},
  {before: woonkamer1, after: woonkamer2},
  {before: woonkamer3, after: woonkamer4},
  {before: badkamer1, after: badkamer2},
  {before: keuken1, after: keuken2},
  {before: keuken3, after: keuken4},
]

export default beforeAndAfterPictures