import { FC, useEffect, useRef } from 'react';
import ImageCompare from "image-compare-viewer";
import '../node_modules/image-compare-viewer/dist/image-compare-viewer.min.css'

import './App.css';
import beforeAndAfterPictures from './images';

const App: FC = () => {

  const containerRefs = useRef<Array<HTMLDivElement | null>>([])

  useEffect(() => {
    if (containerRefs.current)
      containerRefs.current.forEach(element => new ImageCompare(element).mount())
  }, [containerRefs])


  return (
    <div className="App">
      {beforeAndAfterPictures.map((picture, idx) => (
        <div key={idx} ref={el => containerRefs.current[idx] = el}>
          <img src={picture.before} alt="" />
          <img src={picture.after} alt="" />
        </div>        
      ))}
    </div>
  );
}

export default App;
